import { IGetAllRequestModel } from "./model/get-all-request-model";
import { IGetAllFileRequestModel } from "./model/file.model";
import { IDataLoggerFilterRequestModel } from "../features/data-logger/model/request/data-logger-filter.rquest.model";
import { ENV } from "./environment";
import { IGetAllNotificationsRequest } from "../features/notifications/model/request/get-all-notifications.request.model";
import { IGetAllCommentRequestModel } from "./model/comment.model";
import { IMyWorkTaskFilter, IMyWorkWorkflowFilter } from "../features/my-work/model/my-work.model";

const { baseUrl } = ENV();

export default class ApiUrls {
    static Auth = class {
        static Login = () => ApiUrls.getFullUrl("", "login");
        static ForgotPassword = () => ApiUrls.getFullUrl("", "forgot-password");
        static ResetPassword = () => ApiUrls.getFullUrl("", "reset");
    }

    static Category = class {
        private static controller = 'category';
        static GetAll = (request?: IGetAllRequestModel) => ApiUrls.getFullUrl(this.controller, ApiUrls.GetAllQueryString(request));
        static GetById = (id: number) => ApiUrls.getFullUrl(this.controller, `${id}`);
        static Create = () => ApiUrls.getFullUrl(this.controller);
        static Edit = (id: number) => ApiUrls.getFullUrl(this.controller, `${id}`);
        static Delete = (id: number) => ApiUrls.getFullUrl(this.controller, `${id}`);
    }

    static File = class {
        private static controller = 'file';
        static GetAll = (request?: IGetAllFileRequestModel) => ApiUrls.getFullUrl(this.controller, ApiUrls.GetAllQueryString(request));
        static GetById = (id: number) => ApiUrls.getFullUrl(this.controller, `${id}`);
        static Create = () => ApiUrls.getFullUrl(this.controller);
        static Edit = (id: number) => ApiUrls.getFullUrl(this.controller, `${id}`);
        static Download = (id: number) => ApiUrls.getFullUrl(this.controller, `${id}/download`);
        static Delete = (id: number) => ApiUrls.getFullUrl(this.controller, `${id}`);
    }

    static Comment = class {
        private static controller = 'comment';
        static GetAll = (request?: IGetAllCommentRequestModel) => ApiUrls.getFullUrl(this.controller, ApiUrls.GetAllQueryString(request));
        static Create = () => ApiUrls.getFullUrl(this.controller);
        static Edit = (id: number) => ApiUrls.getFullUrl(this.controller, `${id}`);
        static Delete = (id: number) => ApiUrls.getFullUrl(this.controller, `${id}`);
    }

    static Dashboard = class {
        private static controller = 'dashboard';
        static GetSummary = () => ApiUrls.getFullUrl(this.controller, 'summary');
    }

    static Client = class {
        private static controller = 'client';
        static GetAll = (request?: IGetAllRequestModel) => ApiUrls.getFullUrl(this.controller, ApiUrls.GetAllQueryString(request));
        static GetById = (id: number) => ApiUrls.getFullUrl(this.controller, `${id}`);
        static Create = () => ApiUrls.getFullUrl(this.controller);
        static Edit = (id: number) => ApiUrls.getFullUrl(this.controller, `${id}`);
        static Delete = (id: number) => ApiUrls.getFullUrl(this.controller, `${id}`);
    }

    static ClientAddress = class {
        private static controller = 'client/address';
        static GetAll = (request?: IGetAllRequestModel) => ApiUrls.getFullUrl(this.controller, ApiUrls.GetAllQueryString(request));
        static GetById = (id: number) => ApiUrls.getFullUrl(this.controller, `${id}`);
        static Create = () => ApiUrls.getFullUrl(this.controller);
        static Edit = (id: number) => ApiUrls.getFullUrl(this.controller, `${id}`);
        static Delete = (id: number) => ApiUrls.getFullUrl(this.controller, `${id}`);
    }

    static ClientMake = class {
        private static controller = 'client/equipment/make';
        static GetAll = (request?: IGetAllRequestModel) => ApiUrls.getFullUrl(this.controller, ApiUrls.GetAllQueryString(request));
        static GetById = (id: number) => ApiUrls.getFullUrl(this.controller, `${id}`);
        static Create = () => ApiUrls.getFullUrl(this.controller);
        static Edit = (id: number) => ApiUrls.getFullUrl(this.controller);
        static Delete = (id: number) => ApiUrls.getFullUrl(this.controller, `${id}`);
    }

    static ClientEquipment = class {
        private static parent ='client';
        private static controller = 'client/equipment';
        static GetAllByClientId = (id:number) => ApiUrls.getFullUrl(this.parent,`${id}/equipment`);
        static GetAll = (request?: IGetAllRequestModel) => ApiUrls.getFullUrl(this.controller, ApiUrls.GetAllQueryString(request));
        static GetById = (id: number) => ApiUrls.getFullUrl(this.controller, `${id}`);
        static Create = () => ApiUrls.getFullUrl(this.controller);
        static Edit = (id: number) => ApiUrls.getFullUrl(this.controller);
        static Delete = (id: number) => ApiUrls.getFullUrl(this.controller, `${id}`);
    }

    static Workflow = class {
        private static controller = 'workflow';
        static GetAll = (request?: IGetAllRequestModel) => ApiUrls.getFullUrl(this.controller, ApiUrls.GetAllQueryString(request));
        static Create = () => ApiUrls.getFullUrl(this.controller);
        static Edit = (id: number) => ApiUrls.getFullUrl(this.controller, `${id}`);
        static GetById = (id: number) => ApiUrls.getFullUrl(this.controller, `${id}`);
        static NextStage = (id: number) => ApiUrls.getFullUrl(this.controller, `${id}/stage/move/next`);
        static RejectStage = (id: number) => ApiUrls.getFullUrl(this.controller, `${id}/stage/move/reject`);
        static Complete = (id: number) => ApiUrls.getFullUrl(this.controller, `${id}/complete`);
        static GetDefaultStageFlow = () => ApiUrls.getFullUrl(this.controller, 'default/stage-flow');
        static Delete = (id: number) => ApiUrls.getFullUrl(this.controller, `${id}`);
        static Watch = (id: number, watch: boolean = true) => ApiUrls.getFullUrl(this.controller, `${id}/watch/${watch ? "start" : "stop"}`);
    }

    static WorkflowClientActivity = class {
        private static controller = 'workflow/client-activity';
        static GetAll = (request?: IGetAllRequestModel) => ApiUrls.getFullUrl(this.controller, ApiUrls.GetAllQueryString(request));
        static Create = () => ApiUrls.getFullUrl(this.controller);
        static Edit = (id: number) => ApiUrls.getFullUrl(this.controller, `${id}`);
        static GetById = (id: number) => ApiUrls.getFullUrl(this.controller, `${id}`);
        static Delete = (id: number) => ApiUrls.getFullUrl(this.controller, `${id}`);
    }

    static WorkflowTask = class {
        private static controller = 'workflow/task';
        static GetAll = (request?: IGetAllRequestModel) => ApiUrls.getFullUrl(this.controller, ApiUrls.GetAllQueryString(request));
        static Create = () => ApiUrls.getFullUrl(this.controller);
        static Edit = (id: number) => ApiUrls.getFullUrl(this.controller, `${id}`);
        static GetById = (id: number) => ApiUrls.getFullUrl(this.controller, `${id}`);
        static NextStage = (id: number) => ApiUrls.getFullUrl(this.controller, `${id}/stage/move/next`);
        static RejectStage = (id: number) => ApiUrls.getFullUrl(this.controller, `${id}/stage/move/reject`);
        static Complete = (id: number) => ApiUrls.getFullUrl(this.controller, `${id}/complete`);
        static GetDefaultStageFlow = () => ApiUrls.getFullUrl(this.controller, 'default/stage-flow');
        static Delete = (id: number) => ApiUrls.getFullUrl(this.controller, `${id}`);
        static GetRemainingQuantity = (workflowId: number, poItemId: number) => ApiUrls.getFullUrl(this.controller, `workflow/${workflowId}/po-item/${poItemId}`);
        static Watch = (id: number, watch: boolean = true) => ApiUrls.getFullUrl(this.controller, `${id}/watch/${watch ? "start" : "stop"}`);
    }

    static MyWork = class {
        private static controller = 'my-work';
        static GetWorkflow = (query?: IMyWorkWorkflowFilter) => ApiUrls.getFullUrl(this.controller, `workflow${ApiUrls.GetAllQueryString(query)}`);
        static GetTask = (query?: IMyWorkTaskFilter) => ApiUrls.getFullUrl(this.controller, `task${ApiUrls.GetAllQueryString(query)}`);
    }
    static PurchaseOrder = class {
        private static controller = 'po';
        static GetAll = (request?: IGetAllRequestModel) => ApiUrls.getFullUrl(this.controller, ApiUrls.GetAllQueryString(request));
        static GetById = (id: number) => ApiUrls.getFullUrl(this.controller, `${id}`);
        static Create = () => ApiUrls.getFullUrl(this.controller);
        static Edit = (id: number) => ApiUrls.getFullUrl(this.controller, `${id}`);
        static Delete = (id: number) => ApiUrls.getFullUrl(this.controller, `${id}`);
        static Watch = (id: number, watch: boolean = true) => ApiUrls.getFullUrl(this.controller, `${id}/watch/${watch ? "start" : "stop"}`);
    }

    static PurchaseOrderLineItem = class {
        private static controller = 'po/item';
        static GetAll = (poId?: number, request?: IGetAllRequestModel) => poId == null
            ? ApiUrls.getFullUrl(`'po/item'`, ApiUrls.GetAllQueryString(request))
            : ApiUrls.getFullUrl(`'po/${poId}/item'`, ApiUrls.GetAllQueryString(request));
        static GetById = (id: number) => ApiUrls.getFullUrl(this.controller, `${id}`);
        static Create = () => ApiUrls.getFullUrl(this.controller);
        static Edit = (id: number) => ApiUrls.getFullUrl(this.controller, `${id}`);
        static Delete = (id: number) => ApiUrls.getFullUrl(this.controller, `${id}`);
    }

    static PurchaseOrderItemService = class {
        private static controller = 'po/service';
        static GetAll = (request?: IGetAllRequestModel) => ApiUrls.getFullUrl(this.controller, ApiUrls.GetAllQueryString(request));
        static GetById = (id: number) => ApiUrls.getFullUrl(this.controller, `${id}`);
        static Create = () => ApiUrls.getFullUrl(this.controller);
        static Edit = (id: number) => ApiUrls.getFullUrl(this.controller, `${id}`);
        static Delete = (id: number) => ApiUrls.getFullUrl(this.controller, `${id}`);
    }

    static User = class {
        private static controller = 'user';
        static GetAll = (request?: IGetAllRequestModel) => ApiUrls.getFullUrl(this.controller, ApiUrls.GetAllQueryString(request));
        static GetCurrentUser = () => ApiUrls.getFullUrl(this.controller, `current`);
        static GetById = (id: number) => ApiUrls.getFullUrl(this.controller, `${id}`);
        static Create = () => ApiUrls.getFullUrl(this.controller);
        static Edit = (id: number) => ApiUrls.getFullUrl(this.controller, `${id}`);
        static PasswordChange = (id: number) => ApiUrls.getFullUrl(this.controller, `${id}/password-change`);
        static PasswordReset = (id: number) => ApiUrls.getFullUrl(this.controller, `${id}/password-reset`);
        static Delete = (id: number) => ApiUrls.getFullUrl(this.controller, `${id}`);
    }

    static AccessRole = class {
        private static controller = 'access/role';
        static GetAll = (request?: IGetAllRequestModel) => ApiUrls.getFullUrl(this.controller, ApiUrls.GetAllQueryString(request));
        static GetById = (id: number) => ApiUrls.getFullUrl(this.controller, `${id}`);
        static Create = () => ApiUrls.getFullUrl(this.controller);
        static Edit = (id: number) => ApiUrls.getFullUrl(this.controller, `${id}`);
        static Delete = (id: number) => ApiUrls.getFullUrl(this.controller, `${id}`);
    }

    static Feature = class {
        private static controller = 'access/feature';
        static GetAll = (request?: IGetAllRequestModel) => ApiUrls.getFullUrl(this.controller, ApiUrls.GetAllQueryString(request));
        static GetById = (id: number) => ApiUrls.getFullUrl(this.controller, `${id}`);
        static Create = () => ApiUrls.getFullUrl(this.controller);
        static Edit = (id: number) => ApiUrls.getFullUrl(this.controller, `${id}`);
        static Delete = (id: number) => ApiUrls.getFullUrl(this.controller, `${id}`);
    }

    static DataLogger = class {
        private static controller = 'data-logger';
        static GetAll = (request?: IDataLoggerFilterRequestModel) => ApiUrls.getFullUrl(this.controller, ApiUrls.GetAllQueryString(request));
        static GetById = (id: number) => ApiUrls.getFullUrl(this.controller, `${id}`);
        static Create = () => ApiUrls.getFullUrl(this.controller);
        static Edit = (id: number) => ApiUrls.getFullUrl(this.controller, `${id}`);
        static Delete = (id: number) => ApiUrls.getFullUrl(this.controller, `${id}`);
    }

    static DataLoggerMake = class {
        private static controller = 'data-logger/make';
        static GetAll = (request?: IGetAllRequestModel) => ApiUrls.getFullUrl(this.controller, ApiUrls.GetAllQueryString(request));
        static GetById = (id: number) => ApiUrls.getFullUrl(this.controller, `${id}`);
        static Create = () => ApiUrls.getFullUrl(this.controller);
        static Edit = (id: number) => ApiUrls.getFullUrl(this.controller, `${id}`);
        static Delete = (id: number) => ApiUrls.getFullUrl(this.controller, `${id}`);
    }

    static Booking = class {
        private static controller = 'booking';
        static GetAll = (request?: IGetAllRequestModel) => ApiUrls.getFullUrl(this.controller, ApiUrls.GetAllQueryString(request));
        static GetById = (id: number) => ApiUrls.getFullUrl(this.controller, `${id}`);
        static Create = () => ApiUrls.getFullUrl(this.controller);
        static Edit = (id: number) => ApiUrls.getFullUrl(this.controller, `${id}`);
        static Delete = (id: number) => ApiUrls.getFullUrl(this.controller, `${id}`);
    }


    static Team = class {
        private static controller = 'team';
        static GetAll = (request?: any) => ApiUrls.getFullUrl(this.controller, ApiUrls.GetAllQueryString(request));
        static GetById = (id: number) => ApiUrls.getFullUrl(this.controller, `${id}`);
        static Create = () => ApiUrls.getFullUrl(this.controller);
        static GetSalesAssigneeList = () => ApiUrls.getFullUrl(this.controller, "sales/members");
        static GetWorkAllocationAssigneeList = () => ApiUrls.getFullUrl(this.controller, "po-work-allocation/members");
        static Edit = (id: number) => ApiUrls.getFullUrl(this.controller, `${id}`);
        static Delete = (id: number) => ApiUrls.getFullUrl(this.controller, `${id}`);
    }

    static Notification = class {
        private static controller = 'notification';
        static QuickView = () => ApiUrls.getFullUrl(this.controller, "quickview");
        static GetAll = (request: IGetAllNotificationsRequest) => ApiUrls.getFullUrl(this.controller, ApiUrls.GetAllQueryString(request));
        static MarkAllRead = () => ApiUrls.getFullUrl(this.controller, 'markall-read');
        static MarkRead = (id: number) => ApiUrls.getFullUrl(this.controller, `${id}/mark-read`);
    }

    static WorkAllocation = class {
        private static controller = 'po-item/work-allocation';
        static Create = () => ApiUrls.getFullUrl(this.controller);
        static Edit = () => ApiUrls.getFullUrl(this.controller);
    }

    static Report = class {
        private static controller = 'report';
        static GetAll = () => ApiUrls.getFullUrl(this.controller);
        static GetAllEUGMPClasses = ()=> ApiUrls.getFullUrl(`${this.controller}/standard`,'eugm');
        static GetAllISOClasses = ()=> ApiUrls.getFullUrl(`${this.controller}/standard`,'iso');
        static GetAllEUGMPClassById = (id:string)=> ApiUrls.getFullUrl(`${this.controller}/standard`,`eugm/${id}`);
        static GetAllISOClassById = (id:string)=> ApiUrls.getFullUrl(`${this.controller}/standard`,`iso/${id}`);
        
        static CleanroomEquipment = class {
            private static controller = 'report/cleanroom/equipment';
            static Create = () => ApiUrls.getFullUrl(this.controller);
            static GetById = (id: number) => ApiUrls.getFullUrl(this.controller, `${id}`);
            static Edit = (id: number) => ApiUrls.getFullUrl(this.controller);
            static Delete = (id:number)=> ApiUrls.getFullUrl(this.controller, `${id}`);
        }

        static Test = class {
            private static controller = 'report/test';
            static Create = (testName: string) => ApiUrls.getFullUrl(encodeURI(`${this.controller}/${testName}`));
            static GetById = (testName: string, id: number) => ApiUrls.getFullUrl(encodeURI(`${this.controller}/${testName}`), `${id}`);
            static Edit = (testName: string, id: number) => ApiUrls.getFullUrl(encodeURI(`${this.controller}/${testName}`));
            static Delete = (testName: string, id: number) => ApiUrls.getFullUrl(encodeURI(`${this.controller}/${testName}`),`${id}`);
        }

        static Observation = class {
            private static controller = 'report/test';
            private static param = 'observation';
            static Create = (testName: string) => ApiUrls.getFullUrl(encodeURI(`${this.controller}/${testName}/${this.param}`));
            static GetById = (testName: string, id: number) => ApiUrls.getFullUrl(encodeURI(`${this.controller}/${testName}/${this.param}`), `${id}`);
            static Edit = (testName: string, id: number) => ApiUrls.getFullUrl(encodeURI(`${this.controller}/${testName}/${this.param}`));
            static Delete = (testName: string, id: number) => ApiUrls.getFullUrl(encodeURI(`${this.controller}/${testName}/${this.param}`),`${id}`);
        }

        static Reading = class {
            private static controller = 'report/test';
            private static param = 'observation/room';
            static Create = (testName: string) => ApiUrls.getFullUrl(encodeURI(`${this.controller}/${testName}/${this.param}`));
            static GetById = (testName: string, id: number) => ApiUrls.getFullUrl(encodeURI(`${this.controller}/${testName}/${this.param}`), `${id}`);
            static Edit = (testName: string, id: number) => ApiUrls.getFullUrl(encodeURI(`${this.controller}/${testName}/${this.param}`));
            static Delete = (testName: string, id: number) => ApiUrls.getFullUrl(encodeURI(`${this.controller}/${testName}/${this.param}`),`${id}`);
        }
    }

    static getFullUrl(controller: string, route?: string): string {
        const controllerUri = controller != null && controller !== "" ? `/${controller}` : ''
        return baseUrl + '/api' + controllerUri + `${route != null && route !== "" ? '/' + route : ''}`;
    }

    static GetAllQueryString(request?: any) {
        if (request == null) return "";

        const searchQuery = this.MapQueryString(request);
        return searchQuery != null && searchQuery != "" ? `?${searchQuery}` : "";
    }

    static MapQueryString(obj?: any) {
        if (obj == null) return "";

        const str: string[] = [];
        for (const p in obj)
            if (obj.hasOwnProperty(p) && obj[p] != null && obj[p] != "") {
                str.push(`${p}=${encodeURIComponent(obj[p])}`)
            }
        return str.join("&");
    }
}
