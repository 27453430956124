import { Route, Routes, matchPath, useLocation } from "react-router-dom";
import AccountLayout from "../../common/screen/layout/account.layout";
import ScreenUrls from "../../common/screen-urls";
import ReportPage from "./screen/report.page";
import CleanroomEquipmentCreateEditPage from "./screen/cleanroom/equipment/form/equipment-create-edit-page";
import AVTestManagerComponent from "./screen/cleanroom/equipment/tests/airflow-velocity-test/av-test-create-edit-page";
import FITestManagerComponent from "./screen/cleanroom/equipment/tests/filter-integrity-test/fi-test-create-edit-page";
import RouteHelper from "../../common/helper/route-helper";
import { AccessLevel, CleanroomEquipmentTests, FeatureNames } from "../../common/enums";
import AccessDeniedPage from "../../common/screen/anonymous/access-denied.page";

const ReportRoutes = () => {
    const location = useLocation();
    const report = RouteHelper.GetFeatureAccessLevel(FeatureNames.Report);

    return <Routes>
        <Route element={<AccountLayout />}>
            <Route path={ScreenUrls.Report.Index()}>
                <Route index
                    element={report.accessLevel >= AccessLevel.View ? <ReportPage accessLevel={report.accessLevel} /> : <AccessDeniedPage />}
                />
            </Route>
            <Route path={ScreenUrls.Report.CleanroomEquipment.Index()}>
                <Route path={ScreenUrls.CreateUri}
                    element={report.accessLevel >= AccessLevel.Create ? <CleanroomEquipmentCreateEditPage accessLevel={report.accessLevel} /> : <AccessDeniedPage />} />
                <Route
                    path={ScreenUrls.EditUriParam}
                    element={
                        report.accessLevel >= AccessLevel.Edit ? <CleanroomEquipmentCreateEditPage accessLevel={report.accessLevel}
                            id={
                                matchPath({ path: ScreenUrls.Report.CleanroomEquipment.Edit(":id") }, location.pathname)?.params["id"]}
                        /> : <AccessDeniedPage />} />

                {/* ----- airflow velocity test ----- */}
                <Route
                    path={`${ScreenUrls.EditUriParam}/${CleanroomEquipmentTests.airflowvelocity}/${ScreenUrls.CreateUri}`}
                    element={report.accessLevel >= AccessLevel.Create ? <AVTestManagerComponent
                        accessLevel={report.accessLevel}
                        id={
                            Number(matchPath({ path: `${ScreenUrls.Report.CleanroomEquipment.Edit(":id")}/${CleanroomEquipmentTests.airflowvelocity}/${ScreenUrls.CreateUri}` }, location.pathname)?.params["id"])}
                    /> : <AccessDeniedPage />} />

                <Route
                    path={`${ScreenUrls.EditUriParam}/${CleanroomEquipmentTests.airflowvelocity}/${ScreenUrls.EditUri}/:testId`}
                    element={report.accessLevel >= AccessLevel.Edit ? <AVTestManagerComponent
                        testId={Number(matchPath({ path: `${ScreenUrls.Report.CleanroomEquipment.Edit(":id")}/${CleanroomEquipmentTests.airflowvelocity}/${ScreenUrls.EditUri}/:testId` }, location.pathname)?.params["testId"])}
                        accessLevel={report.accessLevel}
                        id={
                            Number(matchPath({ path: `${ScreenUrls.Report.CleanroomEquipment.Edit(":id")}/${CleanroomEquipmentTests.airflowvelocity}/${ScreenUrls.EditUri}/:testId` }, location.pathname)?.params["id"])}
                    /> : <AccessDeniedPage />} />

                {/* ----- filter integrity test ----- */}
                <Route
                    path={`${ScreenUrls.EditUriParam}/${CleanroomEquipmentTests.filterintegrity}/${ScreenUrls.CreateUri}`}
                    element={report.accessLevel >= AccessLevel.Create ? <FITestManagerComponent
                        accessLevel={report.accessLevel}
                        id={
                            Number(matchPath({ path: `${ScreenUrls.Report.CleanroomEquipment.Edit(":id")}/${CleanroomEquipmentTests.filterintegrity}/${ScreenUrls.CreateUri}` }, location.pathname)?.params["id"])}
                    /> : <AccessDeniedPage />} />

                <Route
                    path={`${ScreenUrls.EditUriParam}/${CleanroomEquipmentTests.filterintegrity}/${ScreenUrls.EditUri}/:testId`}
                    element={report.accessLevel >= AccessLevel.Edit ? <FITestManagerComponent
                        testId={Number(matchPath({ path: `${ScreenUrls.Report.CleanroomEquipment.Edit(":id")}/${CleanroomEquipmentTests.filterintegrity}/${ScreenUrls.EditUri}/:testId` }, location.pathname)?.params["testId"])}
                        accessLevel={report.accessLevel}
                        id={
                            Number(matchPath({ path: `${ScreenUrls.Report.CleanroomEquipment.Edit(":id")}/${CleanroomEquipmentTests.filterintegrity}/${ScreenUrls.EditUri}/:testId` }, location.pathname)?.params["id"])}
                    /> : <AccessDeniedPage />} />
            </Route>
        </Route>
    </Routes>
}

export default ReportRoutes;
