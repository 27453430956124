import React from "react";
import { IFormBaseState, FormBaseComponent, } from "../../../../../../../common/component/form-base.component";
import Modal from 'react-modal';
import { connect } from "react-redux";
import { IAppState } from "../../../../../../../store";
import ValidationMessageControl from "../../../../../../../common/control/validation-message.control";
import { filterIntegrityTestObservationDefault } from "../../../../../model/defaults/test.default";
import { IFilterIntegrityModel, IFilterIntegrityObservationModel, IFITestObservationPayload, IFITestPayload } from "../../../../../model/test/filter-integrity-test.model";
import { clearObservationDataState, createFIObservation, updateObservationDataState, editFIObservation, loadFIObservationById } from "../../../../../store/cleanroom-equipment/observation.slice";
import { ValidationScreens, TestObservationResult } from "../../../../../../../common/enums";

export interface IProps {
    toggle: boolean,
    testName: string,
    handleToggleState: any,
    testData: IFilterIntegrityModel,
    observationData: IFilterIntegrityObservationModel,
    clearObservationDataState: (data: IFilterIntegrityObservationModel) => void,
    updateObservationDataState: (data) => void,
    createObservation: (data: IFITestObservationPayload) => void,
    loadObservationById: (data: IFITestObservationPayload) => void,
    editObservation: (data: IFITestObservationPayload) => void,
    defaultData?: null | IFilterIntegrityObservationModel,
}

export interface IState extends IFormBaseState {
}

class FITestObservationComponent extends FormBaseComponent<IProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            hideForm: false,
        }
    }

    async componentDidMount() {
        await this.setValidator(ValidationScreens.FilterIntegrityTestObservation);
        this.props.clearObservationDataState(this.props.defaultData ?? filterIntegrityTestObservationDefault);
    }

    async componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (this.props.defaultData && this.props.defaultData != prevProps.defaultData) {
            this.props.clearObservationDataState(this.props.defaultData);
        }
    }

    async componentWillUnmount() {
        await this.setValidator(ValidationScreens.None);
    }

    render() {
        return <React.Fragment>
            <Modal
                isOpen={this.props.toggle}
                onRequestClose={this.props.handleToggleState}
            >
                <div className={`d-flex f-w-500 flex-column`} style={{ maxHeight: "90vh" }}>
                    <div className="f-18">
                        Add Observations
                    </div>
                    <div className="row my-3">
                        <div className="col-6">
                            <div className={`form-group d-flex flex-column ${this.vResult("filterId").className}`}>
                                <label htmlFor="filterId">Filter Id<span className="requried-span">*</span></label>
                                <input
                                    className="form-control"
                                    value={this.props.observationData?.filterId ?? ""}
                                    onChange={e => {
                                        e.preventDefault();
                                        this.ValidateField("filterId", e.target?.value);
                                        this.props.updateObservationDataState({ name: "filterId", value: e.target?.value });
                                        // this.props.updateHasError(this.hasError);
                                    }} />
                                <ValidationMessageControl message={this.vResult("filterId").message} />
                            </div>
                        </div>
                        <div className="col-6">
                            <div className={`form-group d-flex flex-column ${this.vResult("upstreamConcentrationBeforeIntegrityPercentage").className}`}>
                                <label htmlFor="upstreamConcentrationBeforeIntegrityPercentage">Upstream Concentration Before Integrity (%)<span className="requried-span">*</span></label>
                                <input
                                    className="form-control"
                                    value={this.props.observationData?.upstreamConcentrationBeforeIntegrityPercentage ?? ""}
                                    onChange={e => {
                                        e.preventDefault();
                                        this.ValidateField("upstreamConcentrationBeforeIntegrityPercentage", Number(e.target?.value));
                                        this.props.updateObservationDataState({ name: "upstreamConcentrationBeforeIntegrityPercentage", value: isNaN(Number(e?.target?.value)) ? 0 : e.target.value });
                                        // this.props.updateHasError(this.hasError);
                                    }} />
                                <ValidationMessageControl message={this.vResult("upstreamConcentrationBeforeIntegrityPercentage").message} />
                            </div>
                        </div>
                        <div className="col-6">
                            <div className={`form-group d-flex flex-column ${this.vResult("upstreamConcentrationBeforeIntegrity").className}`}>
                                <label htmlFor="upstreamConcentrationBeforeIntegrity">Upstream Concentration Before Integrity (ug/l)<span className="requried-span">*</span></label>
                                <input
                                    className="form-control"
                                    value={this.props.observationData?.upstreamConcentrationBeforeIntegrity ?? ""}
                                    onChange={e => {
                                        e.preventDefault();
                                        this.ValidateField("upstreamConcentrationBeforeIntegrity", Number(e.target?.value));
                                        this.props.updateObservationDataState({ name: "upstreamConcentrationBeforeIntegrity", value: isNaN(Number(e?.target?.value)) ? 0 : e.target.value });
                                        // this.props.updateHasError(this.hasError);
                                    }} />
                                <ValidationMessageControl message={this.vResult("upstreamConcentrationBeforeIntegrity").message} />
                            </div>
                        </div>
                        <div className="col-6">
                            <div className={`form-group d-flex flex-column ${this.vResult("obtainedResultInDownStreamPercentage").className}`}>
                                <label htmlFor="obtainedResultInDownStreamPercentage"> Obtained Result In DownStream (%)<span className="requried-span">*</span></label>
                                <input
                                    className="form-control"
                                    value={this.props.observationData?.obtainedResultInDownStreamPercentage ?? ""}
                                    onChange={e => {
                                        e.preventDefault();
                                        this.ValidateField("obtainedResultInDownStreamPercentage", e.target?.value);
                                        this.props.updateObservationDataState({ name: "obtainedResultInDownStreamPercentage", value: isNaN(Number(e?.target?.value)) ? 0 : e.target.value });
                                        // this.props.updateHasError(this.hasError);
                                    }} />
                                <ValidationMessageControl message={this.vResult("obtainedResultInDownStreamPercentage").message} />
                            </div>
                        </div>
                        <div className="col-6">
                            <div className={`form-group d-flex flex-column ${this.vResult("upstreamPAOConcentrationAfterIntegrityPercentage").className}`}>
                                <label htmlFor="upstreamPAOConcentrationAfterIntegrityPercentage">Upstream PAO Concentration After Integrity (%)<span className="requried-span">*</span></label>
                                <input
                                    className="form-control"
                                    value={this.props.observationData?.upstreamPAOConcentrationAfterIntegrityPercentage ?? ""}
                                    onChange={e => {
                                        e.preventDefault();
                                        this.ValidateField("upstreamPAOConcentrationAfterIntegrityPercentage", Number(e.target?.value));
                                        this.props.updateObservationDataState({ name: "upstreamPAOConcentrationAfterIntegrityPercentage", value: isNaN(Number(e?.target?.value)) ? 0 : e.target.value });
                                        // this.props.updateHasError(this.hasError);
                                    }} />
                                <ValidationMessageControl message={this.vResult("upstreamPAOConcentrationAfterIntegrityPercentage").message} />
                            </div>
                        </div>
                        <div className="col-6">
                            <div className={`form-group d-flex flex-column ${this.vResult("upstreamPAOBeforeAndAfterIntegrity").className}`}>
                                <label htmlFor="upstreamPAOBeforeAndAfterIntegrity">Upstream PAO Before And After Integrity (%)<span className="requried-span">*</span></label>
                                <input
                                    className="form-control"
                                    value={this.props.observationData?.upstreamPAOBeforeAndAfterIntegrity ?? ""}
                                    onChange={e => {
                                        e.preventDefault();
                                        this.ValidateField("upstreamPAOBeforeAndAfterIntegrity", Number(e.target?.value));
                                        this.props.updateObservationDataState({ name: "upstreamPAOBeforeAndAfterIntegrity", value: isNaN(Number(e?.target?.value)) ? 0 : e.target.value });
                                        // this.props.updateHasError(this.hasError);
                                    }} />
                                <ValidationMessageControl message={this.vResult("upstreamPAOBeforeAndAfterIntegrity").message} />
                            </div>
                        </div>

                        {
                            this.props.observationData?.id ?

                                <div className="col-6">
                                    <div className={`form-group ${this.vResult("name").className}`}>
                                        <label htmlFor="name">Result</label>
                                        <input id="name" className="form-control" type="text"
                                            value={this.props.observationData?.result == TestObservationResult.Pass ? "Pass" :
                                                this.props.observationData?.result == TestObservationResult.Fail ? "Fail" : ""}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                                : ""
                        }
                    </div>
                    <div className="row">
                        <div className="col-12 text-right">
                            <button
                                onClick={(e: any) => {
                                    const error = !this.ValidateForm(this.props.observationData);
                                    this.reloadForm();
                                    if (error) return;
                                    if (this.props.observationData?.id) {
                                        this.props.editObservation({ testName: this.props.testName, data: this.props.observationData })
                                    } else {
                                        let payload = { ...this.props.observationData };
                                        payload.filterIntegrityTestId = this.props.testData.id ?? 0;
                                        this.props.createObservation({ testName: this.props.testName, data: payload });
                                    };
                                    this.props.handleToggleState({ ...this.state, openReadingSection: false, editReadingIndex: -1 });
                                }}
                                className="text-capitalize f-w-800 border-0 btn-primary btn-sm align-self-end">
                                Save Observation
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        </React.Fragment >
    }
}


const mapStateToProps = (state: IAppState) => ({
    observationData: state.testObservation.observationData,
    dataLoading: state.reportTest.dataLoading,
    createEditLoading: state.reportTest.createEditLoading,
});
export default connect(mapStateToProps, {
    clearObservationDataState: clearObservationDataState, updateObservationDataState: updateObservationDataState, createObservation: createFIObservation, editObservation: editFIObservation, loadObservationById: loadFIObservationById
})(FITestObservationComponent);

