import ApiUrls from "../../../common/api-urls";
import HttpService from "../../../common/service/http.service";
import { IAirflowVelocityTestObservationModel } from "../model/test/test.model";
import { ITestObservationPayload } from "../model/test/test.model";
import { IFilterIntegrityObservationModel, IFITestPayload, IFITestObservationPayload } from "../model/test/filter-integrity-test.model";

export default class ObservationService {
    static async LoadAllAVObservation(): Promise<IAirflowVelocityTestObservationModel[]> {
        return await HttpService.Get<IAirflowVelocityTestObservationModel[]>(ApiUrls.Report.GetAll());
    }

    static async CreateAVObservation(payload: ITestObservationPayload): Promise<IAirflowVelocityTestObservationModel> {
        return await HttpService.Post<IAirflowVelocityTestObservationModel | null, IAirflowVelocityTestObservationModel>(ApiUrls.Report.Observation.Create(payload.testName), payload.data ?? null);
    }

    static async EditAVObservation(payload: ITestObservationPayload): Promise<IAirflowVelocityTestObservationModel> {
        return await HttpService.Patch<IAirflowVelocityTestObservationModel | null, IAirflowVelocityTestObservationModel>(ApiUrls.Report.Observation.Edit(payload.testName, payload?.data?.id ?? 0), payload.data ?? null);
    }

    static async DeleteAVObservation(payload:ITestObservationPayload) {
        await HttpService.Delete(ApiUrls.Report.Observation.Delete(payload.testName,payload.id ?? 0));
    }

    static async GetAVObservationById(payload: ITestObservationPayload): Promise<IAirflowVelocityTestObservationModel> {
        return await HttpService.Get<IAirflowVelocityTestObservationModel>(ApiUrls.Report.Observation.GetById(payload.testName, payload.id ?? 0));
    }

    // ==== FI Observation ====

    static async LoadAllFIObservation(): Promise<IFilterIntegrityObservationModel[]> {
        return await HttpService.Get<IFilterIntegrityObservationModel[]>(ApiUrls.Report.GetAll());
    }

    static async CreateFIObservation(payload: IFITestObservationPayload): Promise<IFilterIntegrityObservationModel> {
        return await HttpService.Post<IFilterIntegrityObservationModel | null, IFilterIntegrityObservationModel>(ApiUrls.Report.Observation.Create(payload.testName), payload.data ?? null);
    }

    static async EditFIObservation(payload: IFITestObservationPayload): Promise<IFilterIntegrityObservationModel> {
        return await HttpService.Patch<IFilterIntegrityObservationModel | null, IFilterIntegrityObservationModel>(ApiUrls.Report.Observation.Edit(payload.testName, payload?.data?.id ?? 0), payload.data ?? null);
    }

    static async DeleteFIObservation(payload:IFITestObservationPayload) {
        await HttpService.Delete(ApiUrls.Report.Observation.Delete(payload.testName,payload.id ?? 0));
    }

    static async GetFIObservationById(payload: IFITestObservationPayload): Promise<IFilterIntegrityObservationModel> {
        return await HttpService.Get<IFilterIntegrityObservationModel>(ApiUrls.Report.Observation.GetById(payload.testName, payload.id ?? 0));
    }

}
