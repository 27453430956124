import { createAsyncThunk, createSlice, isFulfilled, PayloadAction } from "@reduxjs/toolkit";
import { ICleanroomEquipmentModel } from '../../model/report.model';
import { IStoreSaveModel } from "../../../../common/model/store-save.model";
import { IAirflowVelocityModel } from "../../model/test/test.model";
import { airflowVelocityTestDefaults } from "../../model/defaults/test.default";
import TestService from "../../service/test.service";
import { createAVObservation, deleteAVObservation,editAVObservation, createAVReading, editAVReading, deleteAVReading, createFIObservation, editFIObservation,deleteFIObservation  } from "./observation.slice";
import ScreenUrls from "../../../../common/screen-urls";
import { CleanroomEquipmentTests } from "../../../../common/enums";
import { IFilterIntegrityModel } from "../../model/test/filter-integrity-test.model";

// ----- AV Test -----
export const loadAVTestById = createAsyncThunk("report/test/AI/loadById", TestService.GetAVById);
export const createAVTest = createAsyncThunk("report/test/AI/create", TestService.CreateAVTest);
export const editAVTest = createAsyncThunk("report/test/AI/edit", TestService.EditAVTest);
export const deleteAVTest = createAsyncThunk("report/test/AV/delete", TestService.DeleteAVTest);
// ----- FI Test -----
export const loadFITestById = createAsyncThunk("report/test/FI/loadById", TestService.GetFITestById);
export const createFITest = createAsyncThunk("report/test/FI/create", TestService.CreateFITest);
export const editFITest = createAsyncThunk("report/test/FI/edit", TestService.EditFITest);
export const deleteFITest = createAsyncThunk("report/test/FI/delete", TestService.DeleteFITest);

export interface ICleanroomEquipmentTestState {
    testList: ICleanroomEquipmentModel[],
    testData: IAirflowVelocityModel | IFilterIntegrityModel,
    dataLoading: boolean,
    listLoading: boolean,
    createEditLoading: boolean,
    stageMoveLoading: boolean,
    refetchApi: boolean
}

const initialState: ICleanroomEquipmentTestState = {
    testList: [],
    testData: airflowVelocityTestDefaults,
    dataLoading: false,
    listLoading: false,
    createEditLoading: false,
    stageMoveLoading: false,
    refetchApi: false,
}

const cleanroomEquipmentSlice = createSlice({
    name: 'cleanroomEquipmentTest',
    initialState,
    reducers: {
        clearTestDataState: (state, action) => {
            state.testData = action.payload
        },
        updateTestDataState: (state, action: PayloadAction<IStoreSaveModel>) => {
            state.testData[action.payload.name] = action.payload.value;
        }
    },
    extraReducers: async (builder) => {
        // create
        builder.addCase(createAVTest.pending, (state, action) => {
            state.createEditLoading = true;
        });
        builder.addCase(createAVTest.rejected, (state, action) => {
            state.createEditLoading = true;
        });
        builder.addCase(createAVTest.fulfilled, (state, action) => {
            state.testData = action.payload;
            state.createEditLoading = false;
            if (action.payload?.cleanroomReportId && action.payload?.id) {
                window.location.href = `${ScreenUrls.Report.CleanroomEquipment.Edit(action.payload?.cleanroomReportId ?? 0)}/${CleanroomEquipmentTests.airflowvelocity}/${ScreenUrls.EditUri}/${action.payload?.id ?? 0}`;
            }

        });

        // load by id
        builder.addCase(loadAVTestById.pending, (state, action) => {
            state.dataLoading = true;
        });
        builder.addCase(loadAVTestById.rejected, (state, action) => {
            state.dataLoading = false;
            state.refetchApi = false;
        });
        builder.addCase(loadAVTestById.fulfilled, (state, action) => {
            state.testData = action.payload;
            state.refetchApi = false;
            state.dataLoading = false;
        });

        // edit
        builder.addCase(editAVTest.pending, (state, action) => {
            state.createEditLoading = true;
        });
        builder.addCase(editAVTest.rejected, (state, action) => {
            state.createEditLoading = true;
        });
        builder.addCase(editAVTest.fulfilled, (state, action) => {
            state.testData = action.payload;
            state.createEditLoading = false;

        });

        // ----- FI test -----

        // create
        builder.addCase(createFITest.pending, (state, action) => {
            state.createEditLoading = true;
        });
        builder.addCase(createFITest.rejected, (state, action) => {
            state.createEditLoading = true;
        });
        builder.addCase(createFITest.fulfilled, (state, action) => {
            state.testData = action.payload;
            state.createEditLoading = false;
            if (action.payload?.cleanroomReportId && action.payload?.id) {
                window.location.href = `${ScreenUrls.Report.CleanroomEquipment.Edit(action.payload?.cleanroomReportId ?? 0)}/${CleanroomEquipmentTests.filterintegrity}/${ScreenUrls.EditUri}/${action.payload?.id ?? 0}`;
            }

        });

        // load by id
        builder.addCase(loadFITestById.pending, (state, action) => {
            state.dataLoading = true;
        });
        builder.addCase(loadFITestById.rejected, (state, action) => {
            state.dataLoading = false;
            state.refetchApi = false;
        });
        builder.addCase(loadFITestById.fulfilled, (state, action) => {
            state.testData = action.payload;
            state.refetchApi = false;
            state.dataLoading = false;
        });

        // edit
        builder.addCase(editFITest.pending, (state, action) => {
            state.createEditLoading = true;
        });
        builder.addCase(editFITest.rejected, (state, action) => {
            state.createEditLoading = true;
        });
        builder.addCase(editFITest.fulfilled, (state, action) => {
            state.testData = action.payload;
            state.createEditLoading = false;

        });


        //---- AV Test ----
        builder.addMatcher(isFulfilled(deleteAVReading, createAVReading, editAVReading, createAVObservation, editAVObservation,deleteAVObservation ), (state, action) => {
            state.refetchApi = true;
        });

        //---- FI Test ----
        builder.addMatcher(isFulfilled(createFIObservation, editFIObservation,deleteFIObservation), (state, action) => {
            state.refetchApi = true;
        });
    }
});

const { actions, reducer } = cleanroomEquipmentSlice;

export const { clearTestDataState, updateTestDataState } = actions;

export default reducer;