import { FormBaseComponent, IFormBaseState } from "../../../../../../../common/component/form-base.component";
import React from "react";
import { connect } from "react-redux";
import { IAppState } from "../../../../../../../store";
import ReactSelect from "react-select";
import { AgGridDefaultColDef } from "../../../../../../../common/app-defaults";
import { AgGridReact } from "ag-grid-react";
import { IUserModel } from "../../../../../../user/model/user.model";
import ScreenUrls from "../../../../../../../common/screen-urls";
import DataLoggerService from "../../../../../../data-logger/service/data-logger.service";
import DataLoggerSelectorAccordionComponent from "../../../../../../workflow-task/component/data-logger-selector.component";
import { Link } from "react-router-dom";
import ValidationMessageControl from "../../../../../../../common/control/validation-message.control";
import { clearTestDataState, updateTestDataState, editFITest, createFITest, loadFITestById } from "../../../../../store/cleanroom-equipment/test.slice";
import { deleteFIObservation } from "../../../../../store/cleanroom-equipment/observation.slice";
import DatePicker from "react-datepicker";
import DropdownDisplayHelper from "../../../../../../../common/helper/dropdown-display.helper";
import { AppConstants } from "../../../../../../../common/app-constants";
import { utcToLocalDate, localToUtcDate } from "../../../../../../../common/utils";
import { IFormControlDropdownItemModel } from "../../../../../../../common/control/model/form.control.field.model";
import AsyncSelect from "react-select/async";
import { DeferredLoadOptions } from "../../../../../../../common/utils";
import { filterIntegrityTestDefault } from "../../../../../model/defaults/test.default";
import UserService from "../../../../../../user/service/user.service";
import { loadCleanroomEquipmentById } from "../../../../../store/cleanroom-equipment/cleanroom-equipment.slice";
import { ICleanroomEquipmentModel } from "../../../../../model/report.model";
import { AccessLevel, AerosolGeneratorType, AerosolMedium, CleanroomEquipmentTests, TestObservationResult, ValidationScreens } from "../../../../../../../common/enums";
import TestObservationComponent from "./test-observation.component";
import FilterIntegrityPDFComponent from "../../../../../component/cleanroom/cleanroom-pdf/filter-integrity.componet";
import { IFilterIntegrityModel, IFilterIntegrityObservationModel, IFITestObservationPayload, IFITestPayload } from "../../../../../model/test/filter-integrity-test.model";
import { AerosolMediumList, AerosolGeneratorTypeList } from "../../../../../../../common/_static/report.data";

export interface IProps {
    id: number,
    testId?: number,
    testData: IFilterIntegrityModel,
    equipmentData: ICleanroomEquipmentModel,
    dataLoading: boolean,
    createFITest: (data: IFITestPayload) => void,
    editFITest: (data: IFITestPayload) => void,
    loadFITestById: (data: IFITestPayload) => void,
    createEditLoading: boolean,
    currentUser: IUserModel,
    clearTestDataState: (payload) => void,
    updateTestDataState: (payload) => void,
    loadCleanroomEquipmentById: (id: number) => void,
    refetchApi: boolean,
    accessLevel:number,
    deleteObservation: (data: IFITestObservationPayload) => void,
}

export interface IState extends IFormBaseState {
    openObservationPopup: boolean,
    editObservation: null | IFilterIntegrityObservationModel,
}

class FITestManager extends FormBaseComponent<IProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            hideForm: false,
            openObservationPopup: false,
            editObservation: null,
        };
    }

    async componentDidMount() {
        await this.setValidator(ValidationScreens.FilterIntegrityTest);
        if (this.props.id) {
            await this.props.loadCleanroomEquipmentById(Number(this.props.id));
        }
        if (this.props.testId) {
            await this.props.loadFITestById({ testName: CleanroomEquipmentTests.filterintegrity ?? "", id: Number(this.props.testId) });
        } else {
            await this.props.clearTestDataState(filterIntegrityTestDefault);
        }
    }

    async componentWillUnmount() {
        await this.setValidator(ValidationScreens.None);
    }

    async componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (this.props.testData != prevProps.testData) {
            if (this.state.editObservation?.id) {
                this.setState({ ...this.state, editObservation: this.props.testData?.observations?.find((item) => item.id == this.state.editObservation?.id) ?? null });
            }
            // --- when adding new observation ----
            else if (this.state.editObservation == null && this.state.openObservationPopup && this.props.testData?.observations) {
                this.setState({ ...this.state, editObservation: this.props.testData?.observations[this.props.testData?.observations?.length - 1] ?? null });
            }
        }
        if (!prevProps.refetchApi && this.props.refetchApi && this.props.testData.id) {
            this.props.loadFITestById({ testName: CleanroomEquipmentTests.filterintegrity ?? "", id: Number(this.props.testId) });
        };
    }

    loadUserOption = (inputValue: string) => DeferredLoadOptions(inputValue, UserService.UserDropdown)

    loadDataLoggers = (inputValue: string) => DeferredLoadOptions(inputValue, (search: string) =>
        new Promise<IFormControlDropdownItemModel[]>(async (resolve) => {
            const dataLoggers = await DataLoggerService.LoadAll();

            if (dataLoggers == null || !Array.isArray(dataLoggers?.data)) {
                resolve([]); return;
            }

            const list = dataLoggers?.data?.map(d => {
                const obj: IFormControlDropdownItemModel = {
                    label: DropdownDisplayHelper.DataLogger(d),
                    value: d.id.toString()
                }
                return obj;
            });

            if (search == null || search === "") resolve(list);
            resolve(list.filter(l => l.label.indexOf(search) > 0))
        }));

    render() {
        if (this.state.hideForm) return;
        return (
            <React.Fragment>
                {(() => {
                    if (this.props.dataLoading || this.props.createEditLoading)
                        return (
                            <div className="loading--bar fixed--top">
                                <span></span>
                            </div>
                        );
                })()}
                <div className="pcoded-content">
                    <div className="pcoded-inner-content">
                        <div className="page-header">
                            <div className="page-block">
                                <div className="row">
                                    <div className="col-md-12 d-flex align-items-center justify-content-between">
                                        <Link
                                            to={ScreenUrls.Report.CleanroomEquipment.Edit(this.props.id)}
                                            className="btn drp-icon btn-rounded btn-primary dropdown-toggle"
                                        >
                                            <i className="feather icon-arrow-left"></i>
                                        </Link>
                                        {
                                            this.props?.testData && this.props.testData.id ?
                                                <FilterIntegrityPDFComponent id={this.props.testData?.id ?? 0}
                                                    currentUser={this.props.currentUser}
                                                    data={{ ...this.props.equipmentData, ...this.props.testData }} />
                                                : ""
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="main-body">
                            <div className="page-wrapper">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <article className="card mb-4">
                                            <div className="card-header card-form-header">
                                                <div className="card-form-header-title">
                                                    Filter Integrity Test Details
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-3">
                                                        <div className={`form-group d-flex flex-column ${this.vResult("dateOfTest").className}`}>
                                                            <label htmlFor="dateOfTest">Date of Test<span className="requried-span">*</span></label>
                                                            <DatePicker key="dateOfTest"
                                                                selected={utcToLocalDate(this.props.testData?.dateOfTest ?? "")}
                                                                dateFormat={AppConstants.DatePickerFormat}
                                                                onChange={(d) => {
                                                                    this.ValidateField("dateOfTest", localToUtcDate(d));
                                                                    this.props.updateTestDataState({ name: "dateOfTest", value: localToUtcDate(d) });
                                                                    // this.props.updateHasError(this.hasError);
                                                                }} />
                                                            <ValidationMessageControl message={this.vResult("dateOfTest").message} />
                                                        </div>
                                                    </div>
                                                    <div className="col-3">
                                                        <div className={`form-group d-flex flex-column ${this.vResult("dueDate").className}`}>
                                                            <label htmlFor="dueDate">Due Date</label>
                                                            <DatePicker key="dueDate"
                                                                selected={utcToLocalDate(this.props.testData?.dueDate ?? "")}
                                                                dateFormat={AppConstants.DatePickerFormat}
                                                                onChange={(d) => {
                                                                    this.ValidateField("dueDate", localToUtcDate(d));
                                                                    this.props.updateTestDataState({ name: "dueDate", value: localToUtcDate(d) });
                                                                    // this.props.updateHasError(this.hasError);
                                                                }} />
                                                            <ValidationMessageControl message={this.vResult("dueDate").message} />
                                                        </div>
                                                    </div>

                                                    <div className="col-3">
                                                        <div className={`form-group ${this.vResult("aerosolGeneratorType").className}`}>
                                                            <label htmlFor="aerosolGeneratorType">Aerosol Generator type</label>
                                                            <ReactSelect id="aerosolGeneratorType"
                                                                className="async-select-control"
                                                                options={AerosolGeneratorTypeList}
                                                                value={this.props.testData?.aerosolGeneratorType != null && this.props.testData?.aerosolGeneratorType > -1 ?
                                                                    AerosolGeneratorTypeList.find((item) => item.value == this.props.testData?.aerosolGeneratorType?.toString())
                                                                    : { label: "Select Type", value: "" }}

                                                                onChange={async (e) => {
                                                                    const cId = Number(e?.value);
                                                                    if (cId === null) return;
                                                                    let standard = AerosolGeneratorTypeList.find((item) => Number(item.value) == cId);
                                                                    this.props.updateTestDataState({ name: "aerosolGeneratorType", value: Number(standard?.value) });
                                                                }}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-3">
                                                        <div className={`form-group ${this.vResult("aerosolMedium").className}`}>
                                                            <label htmlFor="aerosolMedium">Aerosol Medium
                                                            </label>
                                                            <ReactSelect id="aerosolMedium"
                                                                className="async-select-control"
                                                                options={AerosolMediumList}
                                                                value={this.props.testData?.aerosolMedium != null && this.props.testData?.aerosolMedium > -1 ?
                                                                    AerosolMediumList.find((item) => item.value == this.props.testData?.aerosolMedium?.toString())
                                                                    : { label: "Select Type", value: "" }}

                                                                onChange={async (e) => {
                                                                    const cId = Number(e?.value);
                                                                    if (cId === null) return;
                                                                    let standard = AerosolMediumList.find((item) => Number(item.value) == cId);
                                                                    this.props.updateTestDataState({ name: "aerosolMedium", value: Number(standard?.value) });
                                                                }}
                                                            />

                                                        </div>
                                                    </div>

                                                    <div className="col-3">
                                                        <div className={`form-group ${this.vResult("preparedById").className}`}>
                                                            <label htmlFor="preparedById">Prepared By<span className="requried-span">*</span></label>
                                                            <AsyncSelect id="preparedById" cacheOptions defaultOptions
                                                                className="async-select-control"
                                                                loadOptions={this.loadUserOption}
                                                                value={this.props.testData?.preparedBy
                                                                    ? { label: DropdownDisplayHelper.User(this.props.testData?.preparedBy), value: this.props.testData.preparedBy.id.toString() }
                                                                    : { label: "Select User", value: "" }}
                                                                onChange={async (e) => {
                                                                    const cId = Number(e?.value);
                                                                    this.ValidateField("preparedById", cId);
                                                                    if (cId === null || cId === 0 || (this.props.testData.preparedBy != null && cId === this.props.testData.preparedBy.id)) return;

                                                                    const client = await UserService.GetById(cId);

                                                                    this.props.updateTestDataState({ name: "preparedBy", value: client });
                                                                    this.props.updateTestDataState({ name: "preparedById", value: client.id });

                                                                    //this.props.updateHasError(this.hasError);
                                                                }} />
                                                            <ValidationMessageControl message={this.vResult("preparedById").message} />
                                                        </div>
                                                    </div>

                                                    <div className="col-3">
                                                        <div className={`form-group d-flex flex-column ${this.vResult("equipmentName").className}`}>
                                                            <label htmlFor="equipmentName">Prepared On Date</label>
                                                            <DatePicker key="poDate"
                                                                selected={utcToLocalDate(this.props.testData?.preparedOnDate ?? "")}
                                                                dateFormat={AppConstants.DatePickerFormat}
                                                                onChange={(d) => {

                                                                    this.props.updateTestDataState({ name: "preparedOnDate", value: localToUtcDate(d) });
                                                                    // this.props.updateHasError(this.hasError);
                                                                }} />
                                                        </div>
                                                    </div>

                                                    <div className="col-3">
                                                        <div className={`form-group ${this.vResult("checkedById").className}`}>
                                                            <label htmlFor="checkedById">Checked By</label>
                                                            <AsyncSelect id="checkedById" cacheOptions defaultOptions
                                                                className="async-select-control"
                                                                loadOptions={this.loadUserOption}
                                                                value={this.props.testData?.checkedBy
                                                                    ? { label: DropdownDisplayHelper.User(this.props.testData.checkedBy), value: this.props.testData.checkedBy.id.toString() }
                                                                    : { label: "Select User", value: "" }}
                                                                onChange={async (e) => {
                                                                    const cId = Number(e?.value);
                                                                    this.ValidateField("checkedById", cId);
                                                                    if (cId === null || cId === 0 || (this.props.testData.checkedBy != null && cId === this.props.testData.checkedBy.id)) return;

                                                                    const client = await UserService.GetById(cId);

                                                                    this.props.updateTestDataState({ name: "checkedBy", value: client });
                                                                    this.props.updateTestDataState({ name: "checkedById", value: client.id });

                                                                    //this.props.updateHasError(this.hasError);
                                                                }} />
                                                            <ValidationMessageControl message={this.vResult("checkedById").message} />

                                                        </div>
                                                    </div>

                                                    <div className="col-3">
                                                        <div className={`form-group d-flex flex-column ${this.vResult("equipmentName").className}`}>
                                                            <label htmlFor="equipmentName">Checked On Date</label>
                                                            <DatePicker key="poDate"
                                                                selected={utcToLocalDate(this.props.testData?.checkedOnDate ?? "")}
                                                                dateFormat={AppConstants.DatePickerFormat}
                                                                onChange={(d) => {
                                                                    this.props.updateTestDataState({ name: "checkedOnDate", value: localToUtcDate(d) });
                                                                    // this.props.updateHasError(this.hasError);
                                                                }} />
                                                        </div>
                                                    </div>
                                                    <div className="col-3">
                                                        <div className={`form-group ${this.vResult("name").className}`}>
                                                            <label htmlFor="name">Verified By</label>
                                                            <input id="name" className="form-control" type="text"
                                                                value={this.props.testData?.verifiedByName ?? ""}
                                                                onChange={e => {
                                                                    e.preventDefault();
                                                                    this.props.updateTestDataState({ name: "verifiedByName", value: e.target.value })
                                                                    // this.props.updateHasError(this.hasError);
                                                                }} />

                                                        </div>
                                                    </div>
                                                    <div className="col-3">
                                                        <div className={`form-group d-flex flex-column ${this.vResult("equipmentName").className}`}>
                                                            <label htmlFor="equipmentName">Verified On Date</label>
                                                            <DatePicker key="poDate"
                                                                selected={utcToLocalDate(this.props.testData?.verifiedOnDate ?? "")}
                                                                dateFormat={AppConstants.DatePickerFormat}
                                                                onChange={(d) => {
                                                                    // this.ValidateField("poDate", localToUtcDate(d));
                                                                    this.props.updateTestDataState({ name: "verifiedOnDate", value: localToUtcDate(d) });
                                                                    // this.props.updateHasError(this.hasError);
                                                                }} />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 mb-3">
                                                        <div className={`form-group ${this.vResult("dataLoggerId").className}`}>
                                                            <ValidationMessageControl message={this.vResult("dataLoggerId").message} />
                                                            <DataLoggerSelectorAccordionComponent
                                                                defaultSelections={this.props.testData?.dataLogger ? [this.props.testData?.dataLogger] : null}
                                                                category={null}
                                                                onSelection={(d) => {
                                                                    this.ValidateField('dataLoggerId', d.id);
                                                                    this.props.updateTestDataState({ name: "dataLogger", value: d });
                                                                    this.props.updateTestDataState({ name: "dataLoggerId", value: d.id });
                                                                }}
                                                                onUnSelection={(d) => {
                                                                    this.ValidateField('dataLoggerId', 0);
                                                                    this.props.updateTestDataState({ name: "dataLoggerId", value: 0 });
                                                                    this.props.updateTestDataState({ name: "dataLogger", value: null });
                                                                }}
                                                                onUnSelectionAll={() => {
                                                                    this.props.updateTestDataState({ name: "dataLoggerId", value: 0 });
                                                                    this.props.updateTestDataState({ name: "dataLogger", value: null });
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className={`form-group ${this.vResult("equipmentName").className}`}>
                                                            <label htmlFor="equipmentName">Conclusion</label>

                                                            <textarea className="form-control"
                                                                value={this.props.testData?.remarks ?? ""}
                                                                onChange={e => {
                                                                    e.preventDefault();
                                                                    this.props.updateTestDataState({ name: "remarks", value: e.target.value })
                                                                    // this.props.updateHasError(this.hasError);
                                                                }}>
                                                            </textarea>
                                                            {/* <ValidationMessageControl message={this.vResult("poNumber").message} /> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </article>
                                    </div>
                                </div>
                            </div>
                            <article className="card no-bg">
                                <div className="row">
                                    <div className="col-12 text-right">
                                        <button type="button" className="btn mr-0 mb-0 btn-primary"
                                            onClick={async (e) => {
                                                e.preventDefault();
                                                const error = !this.ValidateForm(this.props.testData, this.props.testData);
                                                this.reloadForm();
                                                if (error) return;

                                                if (this.props.testData?.id) {
                                                    await this.props.editFITest({ testName: CleanroomEquipmentTests.filterintegrity ?? "", data: this.props.testData });
                                                } else {
                                                    const { client, clientAddress, createdById, createdBy, preparedBy, checkedBy, reportCategoryId,
                                                        reportcategory,
                                                        airFlowVelocityTests, ...rest } = this.props.testData;
                                                    const payload = {
                                                        ...rest,
                                                        cleanroomReportId: Number(this.props.id ?? 0),
                                                        clientId: this.props.equipmentData.clientId,
                                                        clientAddressId: this.props.equipmentData.clientAddressId,
                                                        clientEquipmentId: this.props.equipmentData.clientEquipmentId,
                                                    };

                                                    this.props.createFITest({ testName: CleanroomEquipmentTests.filterintegrity ?? "", data: payload });
                                                }
                                            }}>Submit</button>
                                    </div>
                                </div>
                            </article>

                            {
                                (() => {
                                    if (!this.props.testData?.id) return;
                                    return (
                                        <article className="card mb-4">
                                            <div className="card-header card-form-header d-flex w-100 align-items-center justify-content-between">
                                                <div className="card-form-header-title">
                                                    Test Observations
                                                </div>

                                                <button className="btn mr-0 mb-0 btn-primary small-btn"
                                                    onClick={() => this.setState({ ...this.state, openObservationPopup: !this.state.openObservationPopup, editObservation: null })}
                                                    disabled={this.props.createEditLoading}
                                                >
                                                    Add Observations
                                                </button>
                                            </div>
                                            <div className="card-body pt-0">
                                                <div className="row">
                                                    <div className="col-12 grid-wrapper">
                                                        <div className="card-block px-0 ag-theme-alpine">
                                                            <AgGridReact
                                                                pagination={true}
                                                                paginationPageSize={10}
                                                                unSortIcon={true}
                                                                rowHeight={60}
                                                                domLayout="autoHeight"
                                                                defaultColDef={AgGridDefaultColDef}
                                                                columnDefs={[
                                                                    {
                                                                        hide: this.props.accessLevel < AccessLevel.Edit,
                                                                        headerName: "Edit", field: 'id', width: 50, cellClass: "grid-cell grid-cell-link",
                                                                        suppressMovable: true,
                                                                        cellRenderer: (params) => {
                                                                            return (

                                                                                <span
                                                                                    onClick={() => {
                                                                                        this.setState({ ...this.state, editObservation: params.data, openObservationPopup: true })
                                                                                    }}
                                                                                >
                                                                                    <i className={`feather icon-edit f-16`}></i>
                                                                                </span>

                                                                            )
                                                                        }
                                                                    },
                                                                    {
                                                                        hide: this.props.accessLevel < AccessLevel.Delete,
                                                                        headerName: "Delete", field: 'id', width: 50, cellClass: "grid-cell grid-cell-link",
                                                                        suppressMovable: true,
                                                                        cellRenderer: (params) => {
                                                                            return (

                                                                                <span
                                                                                    onClick={async () => {
                                                                                        this.props.deleteObservation({ testName: CleanroomEquipmentTests.filterintegrity, id: params.value });
                                                                                    }}
                                                                                >
                                                                                    <i className={`feather icon-trash ml-2 f-16`}></i>
                                                                                </span>

                                                                            )
                                                                        }
                                                                    },
                                                                    {
                                                                        headerName: "ID#", field: "id", width: 80, sortable: true, cellClass: "grid-cell",
                                                                        suppressMovable: true,
                                                                        cellRenderer: (params) => <span>{params.value}</span>
                                                                    },
                                                                    {
                                                                        headerName: "Filter Id", field: "filterId", flex: 1, sortable: true, cellClass: "grid-cell",
                                                                        suppressMovable: true,
                                                                        cellRenderer: (params) => <span>{params.value}</span>
                                                                    },
                                                                    {
                                                                        headerName: "Upstream Concentration Before Integrity (%)", field: "upstreamConcentrationBeforeIntegrityPercentage", width: 200, sortable: true, cellClass: "grid-cell",
                                                                        suppressMovable: true,
                                                                        cellRenderer: (params) => <span>{params.value}</span>
                                                                    },
                                                                    {
                                                                        headerName: "Upstram Concentration Before Integrity (ug/l)", field: "upstreamConcentrationBeforeIntegrity", width: 200, sortable: true, cellClass: "grid-cell",
                                                                        suppressMovable: true,
                                                                        cellRenderer: (params) => <span>{params.value}</span>
                                                                    },
                                                                    {
                                                                        headerName: "Obtained Result In DownStream (%)", field: "obtainedResultInDownStreamPercentage", width: 150, sortable: true, cellClass: "grid-cell",
                                                                        suppressMovable: true,
                                                                        cellRenderer: (params) => <span>{params.value}</span>
                                                                    },
                                                                    {
                                                                        headerName: "Result", field: "result", width: 100, sortable: true, cellClass: "grid-cell",
                                                                        suppressMovable: true,
                                                                        cellRenderer: (params) => {
                                                                            let value = params.value == TestObservationResult.Pass ? TestObservationResult[TestObservationResult.Pass] : params.value == TestObservationResult.Fail ? TestObservationResult[TestObservationResult.Fail] : "NA";

                                                                            return (
                                                                                <span>{value ?? ""}
                                                                                </span>
                                                                            )

                                                                        }
                                                                    },
                                                                ]}
                                                                rowData={this.props.testData?.observations ?? []}>
                                                            </AgGridReact>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </article>
                                    )
                                })()
                            }

                            {
                                this.props.testData?.id && this.state.openObservationPopup ?
                                    <TestObservationComponent
                                        toggle={this.state.openObservationPopup}
                                        handleToggleState={() => this.setState({ ...this.state, openObservationPopup: false, editObservation: null })}
                                        testData={this.props.testData}
                                        testName={CleanroomEquipmentTests.filterintegrity ?? ""}
                                        defaultData={this.state?.editObservation}
                                    />
                                    : null
                            }

                        </div>
                    </div>
                </div >
            </React.Fragment >
        );
    }
}

const mapStateToProps = (state: IAppState) => ({
    testData: state.reportTest.testData,
    dataLoading: state.reportTest.dataLoading,
    createEditLoading: state.reportTest.createEditLoading,
    currentUser: state.userSession.currentUser,
    equipmentData: state.cleanroomequipment.equipmentData,
    refetchApi: state.reportTest.refetchApi,
});
export default connect(mapStateToProps, {
    clearTestDataState, updateTestDataState, deleteObservation: deleteFIObservation, editFITest, createFITest, loadFITestById, loadCleanroomEquipmentById
})(FITestManager);



