import React from "react";
import { IFormBaseState, FormBaseComponent, } from "../../../../../../../common/component/form-base.component";
import Modal from 'react-modal';
import { CleanroomEquipmentTests, TestObservationResult, ValidationScreens } from "../../../../../../../common/enums";
import { IAVTestReadingPayload, IRoomReadingModel, ITestModel, IAirflowVelocityTestObservationModel, ITestObservationPayload } from "../../../../../model/test/test.model";
import { connect } from "react-redux";
import { IAppState } from "../../../../../../../store";
import { loadAVObservationById, setDeletdRoomId, createAVObservation, editAVObservation, clearObservationDataState, updateObservationDataState, createAVReading, editAVReading, deleteAVReading } from "../../../../../store/cleanroom-equipment/observation.slice";
import { roomReadingDefault, TestObservationDefaults } from "../../../../../model/defaults/test.default";
import ValidationMessageControl from "../../../../../../../common/control/validation-message.control";
import { AVTestTestOnList, AVTestTestObservationUnitList } from "../../../../../../../common/_static/report.data";
import ReactSelect from "react-select";

export interface IProps {
    toggle: boolean,
    handleToggleState: any,
    testData: ITestModel,
    observationData: IAirflowVelocityTestObservationModel,
    clearObservationDataState: (data: IAirflowVelocityTestObservationModel) => void,
    updateObservationDataState: (data) => void,
    createObservation: (data: ITestObservationPayload) => void,
    loadObservationById: (data: ITestObservationPayload) => void,
    editObservation: (data: ITestObservationPayload) => void,
    testName: string,
    defaultData?: null | IAirflowVelocityTestObservationModel,
    createReading: (data: IAVTestReadingPayload) => void,
    editReading: (data: IAVTestReadingPayload) => void,
    deleteReading: (data: IAVTestReadingPayload) => void,
    deletedRoomId?: number,
    setDeletdRoomId: (data: number) => void,
}

export interface IState extends IFormBaseState {
    readingData: IRoomReadingModel,
    openReadingSection: boolean,
    editReadingIndex: number,
}

class TestObservationComponent extends FormBaseComponent<IProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            readingData: roomReadingDefault,
            hideForm: false,
            openReadingSection: false,
            editReadingIndex: -1,
        }
    }

    async componentDidMount() {
        await this.setValidator(ValidationScreens.AirflowVelocityTestObservation);
        this.props.clearObservationDataState(this.props.defaultData ?? TestObservationDefaults);
    }

    async componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (this.props.defaultData && this.props.defaultData != prevProps.defaultData) {
            this.props.clearObservationDataState(this.props.defaultData);
        }
    }

    async componentWillUnmount() {
        await this.setValidator(ValidationScreens.None);
    }

    render() {
        return <React.Fragment>
            <Modal
                isOpen={this.props.toggle}
                onRequestClose={this.props.handleToggleState}
            >
                <div className={`d-flex f-w-500 flex-column`} style={{ maxHeight: "90vh" }}>
                    <div className="f-18">
                        Add Observations
                    </div>
                    <div>
                        <div className="row mt-3">
                            <div className="col-6">
                                <div className={`form-group ${this.vResult("filterIdOrGrillId").className}`}>
                                    <label htmlFor="filterIdOrGrillId">Filter OR Grill Id<span className="requried-span">*</span></label>
                                    <ReactSelect id="filterIdOrGrillId"
                                        className="async-select-control"
                                        options={AVTestTestOnList}
                                        value={this.props.observationData?.filterIdOrGrillId
                                            ? AVTestTestOnList.find((item) => item.value == this.props.observationData?.filterIdOrGrillId.toString())
                                            : { label: "Select", value: "" }}

                                        onChange={async (e) => {
                                            const cId = Number(e?.value);
                                            if (cId === null || cId === 0) return;
                                            this.ValidateField('filterIdOrGrillId', cId);
                                            let standard = AVTestTestOnList.find((item) => item.value == cId.toString())
                                            this.props.updateObservationDataState({ name: "filterIdOrGrillId", value: Number(standard?.value) });
                                        }}
                                    />
                                    <ValidationMessageControl message={this.vResult("filterIdOrGrillId").message} />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className={`form-group ${this.vResult("observationUnit").className}`}>
                                    <label htmlFor="observationUnit">Unit<span className="requried-span">*</span></label>
                                    <ReactSelect id="observationUnit"
                                        className="async-select-control"
                                        options={AVTestTestObservationUnitList}
                                        value={this.props.observationData?.observationUnit
                                            ? AVTestTestObservationUnitList.find((item) => item.value == this.props.observationData?.observationUnit.toString())
                                            : { label: "Select", value: "" }}

                                        onChange={async (e) => {
                                            const cId = Number(e?.value);
                                            if (cId === null || cId === 0) return;
                                            this.ValidateField('observationUnit', cId);
                                            let standard = AVTestTestObservationUnitList.find((item) => item.value == cId.toString())
                                            this.props.updateObservationDataState({ name: "observationUnit", value: Number(standard?.value) });
                                        }}
                                    />
                                    <ValidationMessageControl message={this.vResult("observationUnit").message} />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className={`form-group ${this.vResult("acceptanceCriteriaMin").className}`}>
                                    <label htmlFor="acceptanceCriteriaMin">Acceptance Criteria Min<span className="requried-span">*</span></label>
                                    <input id="acceptanceCriteriaMin" className="form-control" type="text"
                                        value={this.props.observationData?.acceptanceCriteriaMin ?? ""}
                                        onChange={e => {
                                            e.preventDefault();
                                            this.ValidateField("acceptanceCriteriaMin", Number(e.target?.value));
                                            this.props.updateObservationDataState({ name: "acceptanceCriteriaMin", value: isNaN(Number(e.target?.value)) ? 0 : Number(e?.target?.value) });
                                            // this.props.updateHasError(this.hasError);
                                        }} />
                                    <ValidationMessageControl message={this.vResult("acceptanceCriteriaMin").message} />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className={`form-group ${this.vResult("acceptanceCriteriaMax").className}`}>
                                    <label htmlFor="acceptanceCriteriaMax">Acceptance Criteria Max<span className="requried-span">*</span></label>
                                    <input id="acceptanceCriteriaMax" className="form-control" type="text"
                                        value={this.props.observationData?.acceptanceCriteriaMax ?? ""}
                                        onChange={e => {
                                            e.preventDefault();
                                            this.ValidateField("acceptanceCriteriaMax", Number(e.target?.value));
                                            this.props.updateObservationDataState({ name: "acceptanceCriteriaMax", value: isNaN(Number(e.target?.value)) ? 0 : Number(e?.target?.value) })
                                            // this.props.updateHasError(this.hasError);
                                        }} />
                                    <ValidationMessageControl message={this.vResult("acceptanceCriteriaMax").message} />
                                </div>
                            </div>
                            {
                                this.props.observationData?.id && this.props.observationData?.roomReadings?.length ?
                                    <>
                                        <div className="col-6">
                                            <div className={`form-group ${this.vResult("name").className}`}>
                                                <label htmlFor="name">Average Air Velocity</label>
                                                <input id="name" className="form-control" type="text"
                                                    value={this.props.observationData?.averageAirVelocity ?? ""}
                                                    disabled={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className={`form-group ${this.vResult("name").className}`}>
                                                <label htmlFor="name">Result</label>
                                                <input id="name" className="form-control" type="text"
                                                    value={this.props.observationData?.result == TestObservationResult.Pass ? "Pass" :
                                                        this.props.observationData?.result == TestObservationResult.Fail ? "Fail" : ""}
                                                    disabled={true}
                                                />
                                            </div>
                                        </div>
                                    </>
                                    : ''
                            }
                        </div>
                        <div className="row">
                            <div className="col-12 text-right">
                                <button
                                    onClick={(e: any) => {
                                        const error = !this.ValidateForm(this.props.observationData);
                                        this.reloadForm();
                                        if (error) return;
                                        if (this.props.observationData?.id) {
                                            this.props.editObservation({ testName: this.props.testName, data: this.props.observationData })
                                        } else {
                                            let payload = { ...this.props.observationData };
                                            payload.airFlowVelocityTestId = this.props.testData.id ?? 0;
                                            this.props.createObservation({ testName: this.props.testName, data: payload })
                                        }
                                    }}
                                    className="text-capitalize f-w-800 border-0 btn-primary btn-sm mt-2 align-self-end">
                                    Save Observation
                                </button>
                            </div>
                        </div>
                        {
                            this.props?.observationData && this.props.observationData?.id ?
                                <div className="row">
                                    <div className="col-12">
                                        <div className="d-flex align-items-center my-2 justify-content-between">
                                            <div className="d-flex align-items-center">
                                                Readings <span className="ml-2 btn-rounded btn-primary m-0 font-12 pill">{this.props.observationData?.roomReadings.length ?? 0}</span>
                                            </div>
                                            {
                                                !this.state.openReadingSection && this.props?.observationData?.roomReadings?.length < 11 ?
                                                    <button
                                                        className="text-capitalize f-w-800 border-0 btn-primary btn-sm align-self-end"
                                                        onClick={() => {
                                                            this.setState({ ...this.state, openReadingSection: !this.state.openReadingSection });
                                                        }}>Add Reading</button>
                                                    : null
                                            }
                                        </div>

                                        {
                                            this.state.openReadingSection &&
                                            <div className="row align-items-center">
                                                <div className="col-2">
                                                    Reading :
                                                </div>
                                                <div className="col-4">
                                                    <input id="name" className="form-control" type="text"
                                                        value={this.state.readingData.reading ?? ""}
                                                        onChange={e => {
                                                            e.preventDefault();
                                                            this.ValidateField("", e.target.value);
                                                            this.setState({
                                                                ...this.state,
                                                                readingData: { ...this.state.readingData, reading: isNaN(Number(e.target.value)) ? 0 : Number(e.target.value) }
                                                            });
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-4">
                                                    <button
                                                        className="text-capitalize f-w-800 border-0 btn-primary btn-sm"
                                                        onClick={() => {
                                                            if (this.state.readingData?.reading) {
                                                                let item = { ...this.state.readingData, observationId: this.props.observationData?.id ?? 0 };
                                                                if (this.state.readingData?.observationId && this.state.editReadingIndex > -1) {
                                                                    this.props.editReading({ testName: this.props.testName, data: item });
                                                                } else {
                                                                    this.props.createReading({ testName: this.props.testName, data: item });
                                                                }
                                                                this.setState({ ...this.state, openReadingSection: false, editReadingIndex: -1, readingData: roomReadingDefault });
                                                            }

                                                        }}>
                                                        {this.state.editReadingIndex > -1 ? "Edit" : "Add"}
                                                    </button>
                                                    <button
                                                        className="text-capitalize f-w-800 border-0 btn-primary btn-sm ml-2"
                                                        onClick={() => {

                                                            this.setState({ ...this.state, openReadingSection: false, editReadingIndex: -1, readingData: roomReadingDefault });
                                                        }}>
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        }

                                        {
                                            Array.isArray(this.props.observationData?.roomReadings) && this.props.observationData?.roomReadings?.length > 0 ?
                                                <table className="mt-2 observations-table">
                                                    <tr className="d-flex align-items-center">
                                                        <th className="">Sr No</th>
                                                        <th className="flex-2">Reading</th>
                                                        <th className="flex-2">Action</th>
                                                    </tr>
                                                    {this.props.observationData?.roomReadings?.map((item: IRoomReadingModel, index: number) => {
                                                        return (
                                                            <tr className="d-flex align-items-center">
                                                                <td>{index + 1}</td>
                                                                <td>{item.reading ?? ""}</td>
                                                                <th> <>
                                                                    <span
                                                                        onClick={() => {
                                                                            this.setState({ ...this.state, editReadingIndex: index, readingData: item, openReadingSection: true });
                                                                        }}
                                                                    >
                                                                        <i className={`feather icon-edit f-16  text-green`}></i>
                                                                    </span>
                                                                    <span
                                                                        onClick={() => {
                                                                            this.props.setDeletdRoomId(index);
                                                                            this.props.deleteReading({ testName: CleanroomEquipmentTests.airflowvelocity, id: item.id });
                                                                        }}
                                                                    >
                                                                        <i className={`ml-2 feather icon-trash f-16 text-green`}></i>
                                                                    </span>
                                                                </>
                                                                </th>
                                                            </tr>
                                                        )
                                                    })}
                                                </table>

                                                :
                                                null
                                        }
                                    </div>
                                </div>
                                : null
                        }

                    </div>

                </div>
            </Modal>
        </React.Fragment >
    }
}


const mapStateToProps = (state: IAppState) => ({
    observationData: state.testObservation.observationData,
    dataLoading: state.reportTest.dataLoading,
    createEditLoading: state.reportTest.createEditLoading,
    deleteRoomId: state.testObservation.deletedRoomId,
});
export default connect(mapStateToProps, {
    clearObservationDataState, updateObservationDataState, createObservation: createAVObservation, setDeletdRoomId, loadObservationById: loadAVObservationById, editObservation: editAVObservation, createReading: createAVReading, editReading: editAVReading, deleteReading: deleteAVReading
})(TestObservationComponent);

