import { clientAddressDefault, clientDefault } from "../../../client/model/client.default";
import {ICleanroomEquipmentModel, IReportModel} from "../report.model";
import {ZoneClassificationType} from '../../../../common/enums';

export const reportDefault: IReportModel = {
    id:0,
    type:"",
    Client:clientDefault,
    clientAddress:clientAddressDefault,
    reportCategory:"",
}

export const cleanroomEquipmentDefault:ICleanroomEquipmentModel = {
    clientId:0,
    client:clientDefault,
    clientEquipment:null,
    clientAddress:clientAddressDefault,
    clientAddressId:0,
    clientEquipmentId:0,
    reportCategoryId:0,
    zoneClassificationType:ZoneClassificationType.EUGMP,
    filterEfficiency:"",
    filterType:"",
    occupancyState:0,
}
