import { cleanroomEquipmentDefault } from "./report.default";
import { IAirflowVelocityModel, IAirflowVelocityTestObservationModel, IRoomReadingModel } from "../test/test.model";
import { IFilterIntegrityModel, IFilterIntegrityObservationModel } from "../test/filter-integrity-test.model";
export const airflowVelocityTestDefaults: IAirflowVelocityModel = {
    id: 0,
    dataLoggerId: 0,
    dataLogger: null,
    dateOfTest: "",
    dueDate: "",
    cleanroomReportId: 0,
    remarks: "",
    preparedById: 0,
    preparedOnDate: "",
    checkedOnDate: "",
    verifiedByName: "",
    verifiedOnDate: "",
    observations: [],
    ...cleanroomEquipmentDefault,
}

export const TestObservationDefaults: IAirflowVelocityTestObservationModel = {
    id: 0,
    filterIdOrGrillId: 0,
    observationUnit: 0,
    airFlowVelocityTestId: 0,
    roomReadings: [],
}

export const roomReadingDefault: IRoomReadingModel = {
    reading: "",
    observationId: 0,
}


export const filterIntegrityTestDefault: IFilterIntegrityModel = {
    id: 0,
    dataLoggerId: 0,
    dataLogger: null,
    dateOfTest: "",
    dueDate: "",
    cleanroomReportId: 0,
    remarks: "",
    preparedById: 0,
    preparedOnDate: "",
    checkedOnDate: "",
    verifiedByName: "",
    verifiedOnDate: "",
    aerosolGeneratorType: 0,
    aerosolMedium: 0,
    inference: "",
    observations: [],
    ...cleanroomEquipmentDefault,
}

export const filterIntegrityTestObservationDefault: IFilterIntegrityObservationModel = {
    id: 0,
    filterId: "",
    upstreamConcentrationBeforeIntegrityPercentage: 0,
    obtainedResultInDownStreamPercentage: 0,
    upstreamPAOConcentrationAfterIntegrityPercentage: 0,
    upstreamPAOBeforeAndAfterIntegrity: 0,
    filterIntegrityTestId: 0,
}