import ApiUrls from "../../../common/api-urls";
import HttpService from "../../../common/service/http.service";
import { IRoomReadingModel, IAVTestReadingPayload } from "../model/test/test.model";

export default class ReadingService {
    static async LoadAVReadingsAll(): Promise<IRoomReadingModel[]> {
        return await HttpService.Get<IRoomReadingModel[]>(ApiUrls.Report.GetAll());
    }

    static async CreateAVReading(payload: IAVTestReadingPayload): Promise<IRoomReadingModel> {
        return await HttpService.Post<IRoomReadingModel | null, IRoomReadingModel>(ApiUrls.Report.Reading.Create(payload.testName), payload.data ?? null);
    }

    static async EditAVReading(payload: IAVTestReadingPayload): Promise<IRoomReadingModel> {
        return await HttpService.Patch<IRoomReadingModel | null, IRoomReadingModel>(ApiUrls.Report.Reading.Edit(payload.testName, payload?.data?.id ?? 0), payload.data ?? null);
    }

    static async DeleteAVReading(payload: IAVTestReadingPayload) {
        await HttpService.Delete(ApiUrls.Report.Reading.Delete(payload.testName, payload.id ?? 0));
    }

    static async GetAVReadingById(payload: IAVTestReadingPayload): Promise<IRoomReadingModel> {
        return await HttpService.Get<IRoomReadingModel>(ApiUrls.Report.Observation.GetById(payload.testName, payload.id ?? 0));
    }
}
