import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IStoreSaveModel } from "../../../../common/model/store-save.model";
import { IAirflowVelocityTestObservationModel } from "../../model/test/test.model";
import {IFilterIntegrityObservationModel} from "../../model/test/filter-integrity-test.model";
import { TestObservationDefaults } from "../../model/defaults/test.default";
import ObservationService from "../../service/observation.service";
import ReadingService from "../../service/reading.service";

export const loadAVObservationById = createAsyncThunk("report/observation/av/loadById", ObservationService.GetAVObservationById);
export const createAVObservation = createAsyncThunk("report/observation/av/create", ObservationService.CreateAVObservation);
export const editAVObservation = createAsyncThunk("report/observation/av/edit", ObservationService.EditAVObservation);
export const deleteAVObservation = createAsyncThunk("report/observation/av/delete", ObservationService.DeleteAVObservation);

export const createAVReading = createAsyncThunk("report/observation/av/reading/create", ReadingService.CreateAVReading);
export const editAVReading = createAsyncThunk("report/observation/av/reading/edit", ReadingService.EditAVReading);
export const deleteAVReading = createAsyncThunk("report/observation/av/reading/delete", ReadingService.DeleteAVReading);

export const loadFIObservationById = createAsyncThunk("report/observation/fi/loadById", ObservationService.GetFIObservationById);
export const createFIObservation = createAsyncThunk("report/observation/fi/create", ObservationService.CreateFIObservation);
export const editFIObservation = createAsyncThunk("report/observation/fi/edit", ObservationService.EditFIObservation);
export const deleteFIObservation = createAsyncThunk("report/observation/fi/delete", ObservationService.DeleteFIObservation);

export interface ICleanroomEquipmentObservationState {
    observationList: IAirflowVelocityTestObservationModel[],
    observationData: IAirflowVelocityTestObservationModel | IFilterIntegrityObservationModel,
    dataLoading: boolean,
    listLoading: boolean,
    createEditLoading: boolean,
    stageMoveLoading: boolean,
    deletedRoomId: number,
}

const initialState: ICleanroomEquipmentObservationState = {
    observationList: [],
    observationData: TestObservationDefaults,
    dataLoading: false,
    listLoading: false,
    createEditLoading: false,
    stageMoveLoading: false,
    deletedRoomId: -1,
}

const cleanroomEquipmentObservationSlice = createSlice({
    name: 'observation',
    initialState,
    reducers: {
        clearObservationDataState: (state, action) => { state.observationData = action.payload },
        updateObservationDataState: (state, action: PayloadAction<IStoreSaveModel>) => {
            state.observationData[action.payload.name] = action.payload.value;
        },
        setDeletdRoomId: (state, action: PayloadAction<number>) => {
            state.deletedRoomId = action.payload;
        }
    },
    extraReducers: (builder) => {
        // create
        builder.addCase(createAVObservation.pending, (state, action) => {
            state.createEditLoading = true;
        });
        builder.addCase(createAVObservation.rejected, (state, action) => {
            state.createEditLoading = false;
        });
        builder.addCase(createAVObservation.fulfilled, (state, action) => {
            state.observationData = action.payload;
            state.createEditLoading = false;
        });

        // load by id
        builder.addCase(loadAVObservationById.pending, (state, action) => {
            state.dataLoading = true;
        });
        builder.addCase(loadAVObservationById.rejected, (state, action) => {
            state.dataLoading = false;
        });
        builder.addCase(loadAVObservationById.fulfilled, (state, action) => {
            state.observationData = action.payload;
            state.dataLoading = false;
        });

        // edit
        builder.addCase(editAVObservation.pending, (state, action) => {
            state.createEditLoading = true;
        });
        builder.addCase(editAVObservation.rejected, (state, action) => {
            state.createEditLoading = false;
        });
        builder.addCase(editAVObservation.fulfilled, (state, action) => {
            state.observationData = action.payload;
        });

        // create reading
        builder.addCase(createAVReading.pending, (state, action) => {
            state.createEditLoading = true;
        });
        builder.addCase(createAVReading.rejected, (state, action) => {
            state.createEditLoading = false;
        });
        builder.addCase(createAVReading.fulfilled, (state, action) => {
            state.createEditLoading = false;
        });

        // edit reading
        builder.addCase(editAVReading.pending, (state, action) => {
            state.createEditLoading = true;
        });
        builder.addCase(editAVReading.rejected, (state, action) => {
            state.createEditLoading = false;
        });
        builder.addCase(editAVReading.fulfilled, (state, action) => {
            state.createEditLoading = false;
        });

        // delete reading
        builder.addCase(deleteAVReading.pending, (state, action) => {
            state.createEditLoading = true;
        });
        builder.addCase(deleteAVReading.rejected, (state, action) => {
            state.createEditLoading = false;
        });
        builder.addCase(deleteAVReading.fulfilled, (state, action) => {
            state.createEditLoading = false;
        });
    }
});

const { actions, reducer } = cleanroomEquipmentObservationSlice;

export const { clearObservationDataState, updateObservationDataState, setDeletdRoomId } = actions;

export default reducer;