import {IReportModel} from "../model/report.model";
import ApiUrls from "../../../common/api-urls";
import HttpService from "../../../common/service/http.service";
import { IAirflowVelocityModel,IAVTestPayload } from "../model/test/test.model";
import { IFilterIntegrityModel,IFITestPayload } from "../model/test/filter-integrity-test.model";

export default class TestService {
    static async GetAVById(payload:IAVTestPayload): Promise<IAirflowVelocityModel> {
        return await HttpService.Get<IAirflowVelocityModel>(ApiUrls.Report.Test.GetById(payload.testName,payload.id ?? 0));
    }

    static async LoadAVAll(): Promise<IReportModel[]> {
        return await HttpService.Get<IReportModel[]>(ApiUrls.Report.GetAll());
    }

    static async CreateAVTest(payload:IAVTestPayload): Promise<IAirflowVelocityModel> {
        return await HttpService.Post<IAirflowVelocityModel | null, IAirflowVelocityModel>(ApiUrls.Report.Test.Create(payload.testName), payload.data ?? null);
    }

    static async EditAVTest(payload:IAVTestPayload): Promise<IAirflowVelocityModel> {
        return await HttpService.Patch<IAirflowVelocityModel | null,IAirflowVelocityModel>(ApiUrls.Report.Test.Edit(payload.testName,payload.data?.id ?? 0), payload.data ?? null);
    }

    static async DeleteAVTest(payload:IAVTestPayload) {
        await HttpService.Delete(ApiUrls.Report.Test.Delete(payload.testName,payload.id ?? 0));
    }

    // ---- FI test ---

    static async GetFITestById(payload:IFITestPayload): Promise<IFilterIntegrityModel> {
        return await HttpService.Get<IFilterIntegrityModel>(ApiUrls.Report.Test.GetById(payload.testName,payload.id ?? 0));
    }
    static async LoadFITestAll(): Promise<IReportModel[]> {
        return await HttpService.Get<IReportModel[]>(ApiUrls.Report.GetAll());
    }

    static async CreateFITest(payload:IFITestPayload): Promise<IFilterIntegrityModel> {
        return await HttpService.Post<IFilterIntegrityModel | null, IFilterIntegrityModel>(ApiUrls.Report.Test.Create(payload.testName), payload?.data ?? null);
    }

    static async EditFITest(payload:IFITestPayload): Promise<IFilterIntegrityModel> {
        return await HttpService.Patch<IFilterIntegrityModel | null,IFilterIntegrityModel>(ApiUrls.Report.Test.Edit(payload.testName,payload.data?.id ?? 0), payload?.data ?? null);
    }

    static async DeleteFITest(payload:IFITestPayload) {
        await HttpService.Delete(ApiUrls.Report.Test.Delete(payload.testName,payload.id ?? 0));
    }

}
