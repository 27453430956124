import { IFieldValidationModel, IValidatorModel } from "../../../../../../common/model/validator.model";
import { ValidationScreens, ValidationType, ZoneClassificationType } from "../../../../../../common/enums";

const fields: IFieldValidationModel[] = [
    {
        fieldName: "filterId",
        rules: [
            {
                type: ValidationType.Required,
                message: "Filter id is required"
            }
        ]
    },
    {
        fieldName: "upstreamConcentrationBeforeIntegrityPercentage",
        rules: [
            {
                type: ValidationType.NumberGreaterThanZero,
                message: "Upstream concentration before integrity is required"
            }
        ]
    },
    {
        fieldName: "upstreamConcentrationBeforeIntegrity",
        rules: [
            {
                type: ValidationType.NumberGreaterThanZero,
                message: "Upstream concentration before integrity should be number",
            }
        ]
    },
    {
        fieldName: "obtainedResultInDownStreamPercentage",
        rules: [
            {
                type: ValidationType.NumberGreaterThanZero,
                message: "Obtained result in downstream should be number",
            },
        ]
    },
    {
        fieldName: "upstreamPAOConcentrationAfterIntegrityPercentage",
        rules: [
            {
                type: ValidationType.NumberGreaterThanZero,
                message: "Upstream PAO concentration after integrity should be number",
            },
        ]
    },
    {
        fieldName: "upstreamPAOBeforeAndAfterIntegrity",
        rules: [
            {
                type: ValidationType.NumberGreaterThanZero,
                message: "Upstream PAO before and after integrity should be number",
            },
        ]
    },

]

export const filterIntegrityTestObservationValidator: IValidatorModel = {
    screen: ValidationScreens.FilterIntegrityTestObservation,
    fields: fields
}
