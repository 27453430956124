import { IFieldValidationModel, IValidatorModel } from "../../../../../common/model/validator.model";
import { ValidationScreens, ValidationType, ZoneClassificationType } from "../../../../../common/enums";

const fields: IFieldValidationModel[] = [
    {
        fieldName: "filterIdOrGrillId",
        rules: [
            {
                type: ValidationType.NumberGreaterThanZero,
                message: "FilterOrGrillId is required"
            }
        ]
    },
    {
        fieldName: "observationUnit",
        rules: [
            {
                type: ValidationType.NumberGreaterThanZero,
                message: "Unit is required"
            }
        ]
    },
    {
        fieldName: "acceptanceCriteriaMin",
        rules: [
            {
                type: ValidationType.Required,
                message: "AcceptanceCriteriaMin is required",
            },
            {
                type:ValidationType.NumberOnly,
                message: "AcceptanceCriteriaMin should be number",
            }
        ]
    },
    {
        fieldName: "acceptanceCriteriaMax",
        rules: [
            {
                type: ValidationType.Required,
                message: "AcceptanceCriteriaMax is required",
            },
            {
                type:ValidationType.NumberOnly,
                message: "AcceptanceCriteriaMax should be number",
            },
        ]
    },
]

export const airflowVelocityTestObservationValidator: IValidatorModel = {
    screen: ValidationScreens.AirflowVelocityTestObservation,
    fields: fields
}
